import {
  AuthService,
  IdentityAccessValueFactory,
  PolicyService,
  PolicyType,
  provideIdentityAccessPermissions,
} from '@finverity/identity-access';

import { PayerFinanceRequestCheckerPermissions } from './payer-finance-request-checker-permissions';

const PAYER_FINANCE_REQUEST_CHECKER_IDENTITY_ACCESS_FACTORY: IdentityAccessValueFactory<
  PayerFinanceRequestCheckerPermissions
> = (authService: AuthService, policyService: PolicyService) => {
  return {
    [PayerFinanceRequestCheckerPermissions.ViewInstruments]: true, // TODO: replace for normal Policies: policyService.hasPolicies('view_documents'),
    [PayerFinanceRequestCheckerPermissions.ViewInstrumentDetails]: policyService.hasPolicies(
      PolicyType.VIEW_INSTRUMENT_DETAILS
    ),
    [PayerFinanceRequestCheckerPermissions.ViewInstrumentAttachments]: policyService.hasPolicies(
      PolicyType.VIEW_DOCUMENTS
    ),
    [PayerFinanceRequestCheckerPermissions.ReviewInstruments]: policyService.hasPolicies(
      PolicyType.CONFIRM_INSTRUMENTS_PAYER_FINANCE_REQUEST_CHECKER
    ),
    [PayerFinanceRequestCheckerPermissions.RejectInstruments]: policyService.hasPolicies(
      PolicyType.REJECT_INSTRUMENTS_PAYER_FINANCE_REQUEST_CHECKER
    ),
  };
};

export const PAYER_FINANCE_REQUEST_CHECKER_IDENTITY_ACCESS_PROVIDER = provideIdentityAccessPermissions(
  PAYER_FINANCE_REQUEST_CHECKER_IDENTITY_ACCESS_FACTORY
);
