import {
  AuthService,
  IdentityAccessValueFactory,
  PolicyService,
  PolicyType,
  provideIdentityAccessPermissions,
} from '@finverity/identity-access';

import { PayerFinanceRequestRejectedPermissions } from './payer-finance-request-rejected-permissions';

const PAYER_FINANCE_REQUEST_REJECTED_IDENTITY_ACCESS_FACTORY: IdentityAccessValueFactory<
  PayerFinanceRequestRejectedPermissions
> = (authService: AuthService, policyService: PolicyService) => {
  return {
    [PayerFinanceRequestRejectedPermissions.ViewInstruments]: true, // TODO: replace for normal Policies: policyService.hasPolicies('view_documents'),
    [PayerFinanceRequestRejectedPermissions.ViewInstrumentDetails]: policyService.hasPolicies(
      PolicyType.VIEW_INSTRUMENT_DETAILS
    ),
    [PayerFinanceRequestRejectedPermissions.ViewInstrumentAttachments]: policyService.hasPolicies(
      PolicyType.VIEW_DOCUMENTS
    ),
    [PayerFinanceRequestRejectedPermissions.EditInstrument]: policyService.hasPolicies(PolicyType.EDIT_INSTRUMENTS),
    [PayerFinanceRequestRejectedPermissions.UndoRejection]: policyService.hasPolicies(
      PolicyType.UNDO_REJECTION_PAYER_FINANCE_REQUEST
    ),
  };
};

export const PAYER_FINANCE_REQUEST_REJECTED_IDENTITY_ACCESS_PROVIDER = provideIdentityAccessPermissions(
  PAYER_FINANCE_REQUEST_REJECTED_IDENTITY_ACCESS_FACTORY
);
