import {
  AuthService,
  IdentityAccessValueFactory,
  PolicyService,
  PolicyType,
  provideIdentityAccessPermissions,
} from '@finverity/identity-access';

import { PayerInstrumentApprovalCheckerPermissions } from './payer-instrument-approval-checker-permissions';

const PAYER_INSTRUMENT_APPROVAL_CHECKER_IDENTITY_ACCESS_FACTORY: IdentityAccessValueFactory<
  PayerInstrumentApprovalCheckerPermissions
> = (authService: AuthService, policyService: PolicyService) => {
  return {
    [PayerInstrumentApprovalCheckerPermissions.ViewInstruments]: true, // TODO: replace for normal Policies: policyService.hasPolicies('view_documents'),
    [PayerInstrumentApprovalCheckerPermissions.ViewInstrumentDetails]: policyService.hasPolicies(
      PolicyType.VIEW_INSTRUMENT_DETAILS
    ),
    [PayerInstrumentApprovalCheckerPermissions.ViewInstrumentAttachments]: policyService.hasPolicies(
      PolicyType.VIEW_DOCUMENTS
    ),
    [PayerInstrumentApprovalCheckerPermissions.ReviewInstruments]: policyService.hasPolicies(
      PolicyType.CONFIRM_INSTRUMENTS_PAYER_APPROVAL_CHECKER
    ),
    [PayerInstrumentApprovalCheckerPermissions.RejectInstruments]: policyService.hasPolicies(
      PolicyType.REJECT_INSTRUMENTS_PAYER_APPROVAL_CHECKER
    ),
  };
};

export const PAYER_INSTRUMENT_APPROVAL_CHECKER_IDENTITY_ACCESS_PROVIDER = provideIdentityAccessPermissions(
  PAYER_INSTRUMENT_APPROVAL_CHECKER_IDENTITY_ACCESS_FACTORY
);
