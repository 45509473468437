import { inject } from '@angular/core';
import { Routes } from '@angular/router';

import { AuthenticationFacadeService } from '@finverity/authentication';
import { CompanyType, InstrumentUploaderType } from '@finverity/graphql';
import { HasAccessGuardFactory } from '@finverity/identity-access';
import { provideMarkAsPaidConfig } from '@finverity/platform-app/modules/instrument-approval/providers';
import { INSTRUMENT_DETAILS_ROUTE_PARAM_NAME } from '@finverity/platform-app/modules/instrument-details/constants';

import { INSTRUMENT_EDIT_ROUTE_PARAM_NAME } from '../../instrument-upload/constants';
import { getFunderOrSellerInstrumentApprovalRejectedConfigs } from '../features/funder-approval-rejected-page/constants';
import {
  FUNDER_APPROVAL_REJECTED_IDENTITY_ACCESS_PROVIDER,
  FunderApprovalRejectedPermissions,
} from '../features/funder-approval-rejected-page/identity-access';
import { FUNDER_INSTRUMENT_APPROVAL_CHECKER_CONFIGS } from '../features/funder-instrument-approval-checker-page/constants';
import {
  FUNDER_INSTRUMENT_APPROVAL_CHECKER_IDENTITY_ACCESS_PROVIDER,
  FunderInstrumentApprovalCheckerPermissions,
} from '../features/funder-instrument-approval-checker-page/identity-access';
import { FUNDER_INSTRUMENT_APPROVAL_MAKER_CONFIGS } from '../features/funder-instrument-approval-maker-page/constants';
import {
  FUNDER_INSTRUMENT_APPROVAL_MAKER_IDENTITY_ACCESS_PROVIDER,
  FunderInstrumentApprovalMakerPermissions,
} from '../features/funder-instrument-approval-maker-page/identity-access';
import { MARK_INSTRUMENTS_AS_PAID_WITHOUT_FINANCING_CONFIGS } from '../features/mark-instruments-as-paid-without-financing/constants';
import {
  MARK_AS_PAID_WITHOUT_FINANCING_IDENTITY_ACCESS_PROVIDER,
  MarkAsPaidWithoutFinancingPermissions,
} from '../features/mark-instruments-as-paid-without-financing/identity-access';
import { PAYER_INSTRUMENT_APPROVAL_REJECTED_CONFIGS } from '../features/payer-approval-rejected-page/constants';
import {
  PAYER_APPROVAL_REJECTED_IDENTITY_ACCESS_PROVIDER,
  PayerApprovalRejectedPermissions,
} from '../features/payer-approval-rejected-page/identity-access';
import { PAYER_INSTRUMENT_FINANCE_REQUEST_CHECKER_CONFIGS } from '../features/payer-finance-request-checker-page/constants';
import {
  PAYER_FINANCE_REQUEST_CHECKER_IDENTITY_ACCESS_PROVIDER,
  PayerFinanceRequestCheckerPermissions,
} from '../features/payer-finance-request-checker-page/identity-access';
import { PAYER_INSTRUMENT_FINANCE_REQUEST_MAKER_CONFIGS } from '../features/payer-finance-request-maker-page/constants';
import {
  PAYER_FINANCE_REQUEST_MAKER_IDENTITY_ACCESS_PROVIDER,
  PayerFinanceRequestMakerPermissions,
} from '../features/payer-finance-request-maker-page/identity-access';
import { PAYER_INSTRUMENT_FINANCE_REQUEST_REJECTED_CONFIGS } from '../features/payer-finance-request-rejected-page/constants';
import {
  PAYER_FINANCE_REQUEST_REJECTED_IDENTITY_ACCESS_PROVIDER,
  PayerFinanceRequestRejectedPermissions,
} from '../features/payer-finance-request-rejected-page/identity-access';
import { PAYER_INSTRUMENT_APPROVAL_CHECKER_CONFIGS } from '../features/payer-instrument-approval-checker-page/constants';
import {
  PAYER_INSTRUMENT_APPROVAL_CHECKER_IDENTITY_ACCESS_PROVIDER,
  PayerInstrumentApprovalCheckerPermissions,
} from '../features/payer-instrument-approval-checker-page/identity-access';
import { PAYER_INSTRUMENT_APPROVAL_MAKER_CONFIGS } from '../features/payer-instrument-approval-maker-page/constants';
import {
  PAYER_INSTRUMENT_APPROVAL_MAKER_IDENTITY_ACCESS_PROVIDER,
  PayerInstrumentApprovalMakerPermissions,
} from '../features/payer-instrument-approval-maker-page/identity-access';
import { SELLER_INSTRUMENT_FINANCE_REQUEST_CHECKER_CONFIGS } from '../features/seller-finance-request-checker-page/constants';
import {
  SELLER_FINANCE_REQUEST_CHECKER_IDENTITY_ACCESS_PROVIDER,
  SellerFinanceRequestCheckerPermissions,
} from '../features/seller-finance-request-checker-page/identity-access';
import { SELLER_INSTRUMENT_FINANCE_REQUEST_MAKER_CONFIGS } from '../features/seller-finance-request-maker-page/constants';
import {
  SELLER_FINANCE_REQUEST_MAKER_IDENTITY_ACCESS_PROVIDER,
  SellerFinanceRequestMakerPermissions,
} from '../features/seller-finance-request-maker-page/identity-access';
import { SELLER_INSTRUMENT_FINANCE_REQUEST_REJECTED_CONFIGS } from '../features/seller-finance-request-rejected-page/constants';
import {
  SELLER_FINANCE_REQUEST_REJECTED_IDENTITY_ACCESS_PROVIDER,
  SellerFinanceRequestRejectedPermissions,
} from '../features/seller-finance-request-rejected-page/identity-access';
import { provideInstrumentApprovalConfigs } from '../providers';

const INSTRUMENT_APPROVAL_ROUTES: Routes = [
  {
    path: 'payer-approval-maker',
    title: 'Approve Instruments | Maker Approval',
    providers: [
      provideInstrumentApprovalConfigs(PAYER_INSTRUMENT_APPROVAL_MAKER_CONFIGS),
      PAYER_INSTRUMENT_APPROVAL_MAKER_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () =>
      import('../features/payer-instrument-approval-maker-page/payer-instrument-approval-maker-page.component'),
    canActivate: [HasAccessGuardFactory([[PayerInstrumentApprovalMakerPermissions.ViewInstruments]])],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[PayerInstrumentApprovalMakerPermissions.ViewInstrumentDetails]])],
      },
    ],
  },
  {
    path: 'payer-approval-checker',
    title: 'Approve Instruments | Checker Approval',
    providers: [
      provideInstrumentApprovalConfigs(PAYER_INSTRUMENT_APPROVAL_CHECKER_CONFIGS),
      PAYER_INSTRUMENT_APPROVAL_CHECKER_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () =>
      import('../features/payer-instrument-approval-checker-page/payer-instrument-approval-checker-page.component'),
    canActivate: [HasAccessGuardFactory([[PayerInstrumentApprovalCheckerPermissions.ViewInstruments]])],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[PayerInstrumentApprovalCheckerPermissions.ViewInstrumentDetails]])],
      },
    ],
  },
  {
    path: 'payer-approval-rejected',
    title: 'Approve Instruments | Rejected',
    providers: [
      provideInstrumentApprovalConfigs(PAYER_INSTRUMENT_APPROVAL_REJECTED_CONFIGS),
      PAYER_APPROVAL_REJECTED_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () => import('../features/payer-approval-rejected-page/payer-approval-rejected-page.component'),
    canActivate: [
      HasAccessGuardFactory([
        [PayerApprovalRejectedPermissions.ViewInstruments, PayerApprovalRejectedPermissions.EditInstrument],
      ]),
    ],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[PayerApprovalRejectedPermissions.ViewInstrumentDetails]])],
      },
      {
        path: `edit/:${INSTRUMENT_EDIT_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-upload/features/instrument-edit/shell/instrument-edit.routes'),
        canActivate: [HasAccessGuardFactory([[PayerApprovalRejectedPermissions.EditInstrument]])],
      },
    ],
  },
  {
    path: 'mark-as-paid-without-financing',
    title: 'Approve Instruments | Paid Without Finance',
    providers: [
      provideMarkAsPaidConfig(MARK_INSTRUMENTS_AS_PAID_WITHOUT_FINANCING_CONFIGS),
      MARK_AS_PAID_WITHOUT_FINANCING_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () =>
      import(
        '../features/mark-instruments-as-paid-without-financing/mark-instruments-without-financing-page.component'
      ),
    canActivate: [HasAccessGuardFactory([[MarkAsPaidWithoutFinancingPermissions.ViewInstruments]])],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[MarkAsPaidWithoutFinancingPermissions.ViewInstrumentDetails]])],
      },
    ],
  },
  {
    path: 'seller-finance-request-maker',
    title: 'Request Finance | Request as Seller | Submit Finance Request (Maker)',
    providers: [
      provideInstrumentApprovalConfigs(SELLER_INSTRUMENT_FINANCE_REQUEST_MAKER_CONFIGS),
      SELLER_FINANCE_REQUEST_MAKER_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () =>
      import('../features/seller-finance-request-maker-page/seller-finance-request-maker-page.component'),
    canActivate: [HasAccessGuardFactory([[SellerFinanceRequestMakerPermissions.ViewInstruments]])],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[SellerFinanceRequestMakerPermissions.ViewInstrumentDetails]])],
      },
    ],
  },
  {
    path: 'seller-finance-request-checker',
    title: 'Request Finance | Request as Seller | Approve Finance Request (Checker)',
    providers: [
      provideInstrumentApprovalConfigs(SELLER_INSTRUMENT_FINANCE_REQUEST_CHECKER_CONFIGS),
      SELLER_FINANCE_REQUEST_CHECKER_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () =>
      import('../features/seller-finance-request-checker-page/seller-finance-request-checker-page.component'),
    canActivate: [HasAccessGuardFactory([[SellerFinanceRequestCheckerPermissions.ViewInstruments]])],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[SellerFinanceRequestCheckerPermissions.ViewInstrumentDetails]])],
      },
    ],
  },
  {
    path: 'seller-finance-request-rejected',
    title: 'Request Finance | Request as Seller | Rejected',
    providers: [
      provideInstrumentApprovalConfigs(SELLER_INSTRUMENT_FINANCE_REQUEST_REJECTED_CONFIGS),
      SELLER_FINANCE_REQUEST_REJECTED_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () =>
      import('../features/seller-finance-request-rejected-page/seller-finance-request-rejected-page.component'),
    canActivate: [
      HasAccessGuardFactory([
        [
          SellerFinanceRequestRejectedPermissions.ViewInstruments,
          SellerFinanceRequestRejectedPermissions.EditInstrument,
        ],
      ]),
    ],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[SellerFinanceRequestRejectedPermissions.ViewInstrumentDetails]])],
      },
      {
        path: `edit/:${INSTRUMENT_EDIT_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-upload/features/instrument-edit/shell/instrument-edit.routes'),
        canActivate: [HasAccessGuardFactory([[SellerFinanceRequestRejectedPermissions.EditInstrument]])],
      },
    ],
  },
  {
    path: 'payer-finance-request-maker',
    title: 'Request Finance | Request as Payer | Submit Finance Request (Maker)',
    providers: [
      provideInstrumentApprovalConfigs(PAYER_INSTRUMENT_FINANCE_REQUEST_MAKER_CONFIGS),
      PAYER_FINANCE_REQUEST_MAKER_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () =>
      import('../features/payer-finance-request-maker-page/payer-finance-request-maker-page.component'),
    canActivate: [HasAccessGuardFactory([[PayerFinanceRequestMakerPermissions.ViewInstruments]])],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[PayerFinanceRequestMakerPermissions.ViewInstrumentDetails]])],
      },
    ],
  },
  {
    path: 'payer-finance-request-checker',
    title: 'Request Finance | Request as Payer | Approve Finance Request (Checker)',
    providers: [
      provideInstrumentApprovalConfigs(PAYER_INSTRUMENT_FINANCE_REQUEST_CHECKER_CONFIGS),
      PAYER_FINANCE_REQUEST_CHECKER_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () =>
      import('../features/payer-finance-request-checker-page/payer-finance-request-checker-page.component'),
    canActivate: [HasAccessGuardFactory([[PayerFinanceRequestCheckerPermissions.ViewInstruments]])],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[PayerFinanceRequestCheckerPermissions.ViewInstrumentDetails]])],
      },
    ],
  },
  {
    path: 'payer-finance-request-rejected',
    title: 'Request Finance | Request as Payer | Rejected',
    providers: [
      provideInstrumentApprovalConfigs(PAYER_INSTRUMENT_FINANCE_REQUEST_REJECTED_CONFIGS),
      PAYER_FINANCE_REQUEST_REJECTED_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () =>
      import('../features/payer-finance-request-rejected-page/payer-finance-request-rejected-page.component'),
    canActivate: [
      HasAccessGuardFactory([
        [PayerFinanceRequestRejectedPermissions.ViewInstruments, PayerFinanceRequestRejectedPermissions.EditInstrument],
      ]),
    ],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[PayerFinanceRequestRejectedPermissions.ViewInstrumentDetails]])],
      },
      {
        path: `edit/:${INSTRUMENT_EDIT_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-upload/features/instrument-edit/shell/instrument-edit.routes'),
        canActivate: [HasAccessGuardFactory([[PayerFinanceRequestRejectedPermissions.EditInstrument]])],
      },
    ],
  },
  {
    path: 'funder-approval-maker',
    title: 'Finance Requests | Approve for Funding | Maker Approval',
    providers: [
      provideInstrumentApprovalConfigs(FUNDER_INSTRUMENT_APPROVAL_MAKER_CONFIGS),
      FUNDER_INSTRUMENT_APPROVAL_MAKER_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () =>
      import('../features/funder-instrument-approval-maker-page/funder-instrument-approval-maker-page.component'),
    canActivate: [HasAccessGuardFactory([[FunderInstrumentApprovalMakerPermissions.ViewInstruments]])],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[FunderInstrumentApprovalMakerPermissions.ViewInstrumentDetails]])],
      },
    ],
  },
  {
    path: 'funder-approval-checker',
    title: 'Finance Requests | Approve for Funding | Checker Approval',
    providers: [
      provideInstrumentApprovalConfigs(FUNDER_INSTRUMENT_APPROVAL_CHECKER_CONFIGS),
      FUNDER_INSTRUMENT_APPROVAL_CHECKER_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () =>
      import('../features/funder-instrument-approval-checker-page/funder-instrument-approval-checker-page.component'),
    canActivate: [HasAccessGuardFactory([[FunderInstrumentApprovalCheckerPermissions.ViewInstruments]])],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[FunderInstrumentApprovalCheckerPermissions.ViewInstrumentDetails]])],
      },
    ],
  },
  createFunderApprovalRejectedRoute(InstrumentUploaderType.DealManagerFunder),
  createFunderApprovalRejectedRoute(InstrumentUploaderType.Seller),
];

export default INSTRUMENT_APPROVAL_ROUTES;

function createFunderApprovalRejectedRoute(
  instrumentUploaderType: InstrumentUploaderType.DealManagerFunder | InstrumentUploaderType.Seller
) {
  const canMatchArgumentsMapping = {
    [InstrumentUploaderType.DealManagerFunder]: CompanyType.Funder,
    [InstrumentUploaderType.Seller]: CompanyType.Customer,
  };

  const title =
    canMatchArgumentsMapping[instrumentUploaderType] === CompanyType.Funder
      ? 'Finance Requests | Approve for Funding | Rejected'
      : 'Request Finance | Rejected by Funder';

  return {
    path: 'funder-approval-rejected',
    title,
    providers: [
      provideInstrumentApprovalConfigs(getFunderOrSellerInstrumentApprovalRejectedConfigs(instrumentUploaderType)),
      FUNDER_APPROVAL_REJECTED_IDENTITY_ACCESS_PROVIDER,
    ],
    loadComponent: () => import('../features/funder-approval-rejected-page/funder-approval-rejected-page.component'),
    canActivate: [
      HasAccessGuardFactory([
        [FunderApprovalRejectedPermissions.ViewInstruments, FunderApprovalRejectedPermissions.EditInstrument],
      ]),
    ],
    canMatch: [
      () =>
        inject(AuthenticationFacadeService).userSignal()?.company_type ===
        canMatchArgumentsMapping[instrumentUploaderType],
    ],
    children: [
      {
        path: `view/:${INSTRUMENT_DETAILS_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-details/shell/instrument-details.routes'),
        canActivate: [HasAccessGuardFactory([[FunderApprovalRejectedPermissions.ViewInstrumentDetails]])],
      },
      {
        path: `edit/:${INSTRUMENT_EDIT_ROUTE_PARAM_NAME}`,
        loadChildren: () => import('../../instrument-upload/features/instrument-edit/shell/instrument-edit.routes'),
        canActivate: [HasAccessGuardFactory([[FunderApprovalRejectedPermissions.EditInstrument]])],
      },
    ],
  };
}
