import {
  AuthService,
  IdentityAccessValueFactory,
  PolicyService,
  PolicyType,
  provideIdentityAccessPermissions,
} from '@finverity/identity-access';

import { FunderApprovalRejectedPermissions } from './funder-approval-rejected-permissions';

const FUNDER_APPROVAL_REJECTED_IDENTITY_ACCESS_FACTORY: IdentityAccessValueFactory<
  FunderApprovalRejectedPermissions
> = (authService: AuthService, policyService: PolicyService) => {
  return {
    [FunderApprovalRejectedPermissions.ViewInstruments]: true, // TODO: replace for normal Policies: policyService.hasPolicies('view_documents'),
    [FunderApprovalRejectedPermissions.ViewInstrumentDetails]: policyService.hasPolicies(
      PolicyType.VIEW_INSTRUMENT_DETAILS
    ),
    [FunderApprovalRejectedPermissions.ViewInstrumentAttachments]: policyService.hasPolicies(PolicyType.VIEW_DOCUMENTS),
    [FunderApprovalRejectedPermissions.EditInstrument]: policyService.hasPolicies(PolicyType.EDIT_INSTRUMENTS),
    [FunderApprovalRejectedPermissions.UndoRejection]: policyService.hasPolicies(
      PolicyType.UNDO_REJECTION_FUNDER_APPROVAL
    ),
  };
};

export const FUNDER_APPROVAL_REJECTED_IDENTITY_ACCESS_PROVIDER = provideIdentityAccessPermissions(
  FUNDER_APPROVAL_REJECTED_IDENTITY_ACCESS_FACTORY
);
