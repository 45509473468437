import {
  AuthService,
  IdentityAccessValueFactory,
  PolicyService,
  PolicyType,
  provideIdentityAccessPermissions,
} from '@finverity/identity-access';

import { FunderInstrumentApprovalCheckerPermissions } from './funder-instrument-approval-checker-permissions';

const FUNDER_INSTRUMENT_APPROVAL_CHECKER_IDENTITY_ACCESS_FACTORY: IdentityAccessValueFactory<
  FunderInstrumentApprovalCheckerPermissions
> = (authService: AuthService, policyService: PolicyService) => {
  return {
    [FunderInstrumentApprovalCheckerPermissions.ViewInstruments]: true, // TODO: replace for normal Policies: policyService.hasPolicies('view_documents'),
    [FunderInstrumentApprovalCheckerPermissions.ViewInstrumentDetails]: policyService.hasPolicies(
      PolicyType.VIEW_INSTRUMENT_DETAILS
    ),
    [FunderInstrumentApprovalCheckerPermissions.ViewInstrumentAttachments]: policyService.hasPolicies(
      PolicyType.VIEW_DOCUMENTS
    ),
    [FunderInstrumentApprovalCheckerPermissions.ReviewInstruments]: policyService.hasPolicies(
      PolicyType.CONFIRM_INSTRUMENTS_FUNDER_APPROVAL_CHECKER
    ),
    [FunderInstrumentApprovalCheckerPermissions.RejectInstruments]: policyService.hasPolicies(
      PolicyType.REJECT_INSTRUMENTS_FUNDER_APPROVAL_CHECKER
    ),
  };
};

export const FUNDER_INSTRUMENT_APPROVAL_CHECKER_IDENTITY_ACCESS_PROVIDER = provideIdentityAccessPermissions(
  FUNDER_INSTRUMENT_APPROVAL_CHECKER_IDENTITY_ACCESS_FACTORY
);
