import {
  AuthService,
  IdentityAccessValueFactory,
  PolicyService,
  PolicyType,
  provideIdentityAccessPermissions,
} from '@finverity/identity-access';

import { SellerFinanceRequestMakerPermissions } from './seller-finance-request-maker-permissions';

const SELLER_FINANCE_REQUEST_MAKER_IDENTITY_ACCESS_FACTORY: IdentityAccessValueFactory<
  SellerFinanceRequestMakerPermissions
> = (authService: AuthService, policyService: PolicyService) => {
  return {
    [SellerFinanceRequestMakerPermissions.ViewInstruments]: true, // TODO: replace for normal Policies: policyService.hasPolicies('view_documents'),
    [SellerFinanceRequestMakerPermissions.ViewInstrumentDetails]: policyService.hasPolicies(
      PolicyType.VIEW_INSTRUMENT_DETAILS
    ),
    [SellerFinanceRequestMakerPermissions.ViewInstrumentAttachments]: policyService.hasPolicies(
      PolicyType.VIEW_DOCUMENTS
    ),
    [SellerFinanceRequestMakerPermissions.ReviewInstruments]: policyService.hasPolicies(
      PolicyType.CONFIRM_INSTRUMENTS_SELLER_FINANCE_REQUEST_MAKER
    ),
    [SellerFinanceRequestMakerPermissions.RejectInstruments]: policyService.hasPolicies(
      PolicyType.REJECT_INSTRUMENTS_SELLER_FINANCE_REQUEST_MAKER
    ),
  };
};

export const SELLER_FINANCE_REQUEST_MAKER_IDENTITY_ACCESS_PROVIDER = provideIdentityAccessPermissions(
  SELLER_FINANCE_REQUEST_MAKER_IDENTITY_ACCESS_FACTORY
);
