import {
  AuthService,
  IdentityAccessValueFactory,
  PolicyService,
  PolicyType,
  provideIdentityAccessPermissions,
} from '@finverity/identity-access';

import { SellerFinanceRequestRejectedPermissions } from './seller-finance-request-rejected-permissions';

const SELLER_FINANCE_REQUEST_REJECTED_IDENTITY_ACCESS_FACTORY: IdentityAccessValueFactory<
  SellerFinanceRequestRejectedPermissions
> = (authService: AuthService, policyService: PolicyService) => {
  return {
    [SellerFinanceRequestRejectedPermissions.ViewInstruments]: true, // TODO: replace for normal Policies: policyService.hasPolicies('view_documents'),
    [SellerFinanceRequestRejectedPermissions.ViewInstrumentDetails]: policyService.hasPolicies(
      PolicyType.VIEW_INSTRUMENT_DETAILS
    ),
    [SellerFinanceRequestRejectedPermissions.ViewInstrumentAttachments]: policyService.hasPolicies(
      PolicyType.VIEW_DOCUMENTS
    ),
    [SellerFinanceRequestRejectedPermissions.EditInstrument]: policyService.hasPolicies(PolicyType.EDIT_INSTRUMENTS),
    [SellerFinanceRequestRejectedPermissions.UndoRejection]: policyService.hasPolicies(
      PolicyType.UNDO_REJECTION_SELLER_FINANCE_REQUEST
    ),
  };
};

export const SELLER_FINANCE_REQUEST_REJECTED_IDENTITY_ACCESS_PROVIDER = provideIdentityAccessPermissions(
  SELLER_FINANCE_REQUEST_REJECTED_IDENTITY_ACCESS_FACTORY
);
