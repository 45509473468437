import {
  AuthService,
  IdentityAccessValueFactory,
  PolicyService,
  PolicyType,
  provideIdentityAccessPermissions,
} from '@finverity/identity-access';

import { PayerApprovalRejectedPermissions } from './payer-approval-rejected-permissions';

const PAYER_APPROVAL_REJECTED_IDENTITY_ACCESS_FACTORY: IdentityAccessValueFactory<PayerApprovalRejectedPermissions> = (
  authService: AuthService,
  policyService: PolicyService
) => {
  return {
    [PayerApprovalRejectedPermissions.ViewInstruments]: true, // TODO: replace for normal Policies: policyService.hasPolicies('view_documents'),
    [PayerApprovalRejectedPermissions.ViewInstrumentDetails]: policyService.hasPolicies(
      PolicyType.VIEW_INSTRUMENT_DETAILS
    ),
    [PayerApprovalRejectedPermissions.ViewInstrumentAttachments]: policyService.hasPolicies(PolicyType.VIEW_DOCUMENTS),
    [PayerApprovalRejectedPermissions.EditInstrument]: policyService.hasPolicies(PolicyType.EDIT_INSTRUMENTS),
    [PayerApprovalRejectedPermissions.UndoRejection]: policyService.hasPolicies(
      PolicyType.UNDO_REJECTION_PAYER_APPROVAL
    ),
  };
};

export const PAYER_APPROVAL_REJECTED_IDENTITY_ACCESS_PROVIDER = provideIdentityAccessPermissions(
  PAYER_APPROVAL_REJECTED_IDENTITY_ACCESS_FACTORY
);
