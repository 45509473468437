import {
  AuthService,
  IdentityAccessValueFactory,
  PolicyService,
  PolicyType,
  provideIdentityAccessPermissions,
} from '@finverity/identity-access';

import { MarkAsPaidWithoutFinancingPermissions } from './mark-as-paid-without-financing-permissions';

const MARK_AS_PAID_WITHOUT_FINANCING_IDENTITY_ACCESS_FACTORY: IdentityAccessValueFactory<
  MarkAsPaidWithoutFinancingPermissions
> = (authService: AuthService, policyService: PolicyService) => {
  return {
    [MarkAsPaidWithoutFinancingPermissions.ViewInstruments]: true, // TODO: replace for normal Policies: policyService.hasPolicies('view_documents'),
    [MarkAsPaidWithoutFinancingPermissions.ViewInstrumentDetails]: policyService.hasPolicies(
      PolicyType.VIEW_INSTRUMENT_DETAILS
    ),
    [MarkAsPaidWithoutFinancingPermissions.ViewInstrumentAttachments]: policyService.hasPolicies(
      PolicyType.VIEW_DOCUMENTS
    ),
    [MarkAsPaidWithoutFinancingPermissions.MarkInstrumentsAsPaid]: policyService.hasPolicies(
      PolicyType.MARK_INSTRUMENTS_AS_PAID
    ),
  };
};

export const MARK_AS_PAID_WITHOUT_FINANCING_IDENTITY_ACCESS_PROVIDER = provideIdentityAccessPermissions(
  MARK_AS_PAID_WITHOUT_FINANCING_IDENTITY_ACCESS_FACTORY
);
