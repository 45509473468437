import {
  AuthService,
  IdentityAccessValueFactory,
  PolicyService,
  PolicyType,
  provideIdentityAccessPermissions,
} from '@finverity/identity-access';

import { PayerFinanceRequestMakerPermissions } from './payer-finance-request-maker-permissions';

const PAYER_FINANCE_REQUEST_MAKER_IDENTITY_ACCESS_FACTORY: IdentityAccessValueFactory<
  PayerFinanceRequestMakerPermissions
> = (authService: AuthService, policyService: PolicyService) => {
  return {
    [PayerFinanceRequestMakerPermissions.ViewInstruments]: true, // TODO: replace for normal Policies: policyService.hasPolicies('view_documents'),
    [PayerFinanceRequestMakerPermissions.ViewInstrumentDetails]: policyService.hasPolicies(
      PolicyType.VIEW_INSTRUMENT_DETAILS
    ),
    [PayerFinanceRequestMakerPermissions.ViewInstrumentAttachments]: policyService.hasPolicies(
      PolicyType.VIEW_DOCUMENTS
    ),
    [PayerFinanceRequestMakerPermissions.ReviewInstruments]: policyService.hasPolicies(
      PolicyType.CONFIRM_INSTRUMENTS_PAYER_FINANCE_REQUEST_MAKER
    ),
    [PayerFinanceRequestMakerPermissions.RejectInstruments]: policyService.hasPolicies(
      PolicyType.REJECT_INSTRUMENTS_PAYER_FINANCE_REQUEST_MAKER
    ),
  };
};

export const PAYER_FINANCE_REQUEST_MAKER_IDENTITY_ACCESS_PROVIDER = provideIdentityAccessPermissions(
  PAYER_FINANCE_REQUEST_MAKER_IDENTITY_ACCESS_FACTORY
);
